@import '../breakpoints/breakpoints';
@import '../layout/spacing.scss';

$button-hover: '&:not(.icon-secondary):not(.disabled):not(:active):hover';
$button-active: '&:not(.disabled):active';

%root-vars {
    --border-radius-button: var(--radius-pill);
    --button-height: 3em;
    --button-height-small: 2em;
    --button-height-large: 3.5em;
    --button-border-width: 1px;
    --icon-small-size: 1em;
    --icon-medium-size: 1.5em;
}

.j-button {
    @extend %root-vars;
    position: relative;
    outline: none;
    text-align: center;
    cursor: pointer;
    margin: 0;
    box-sizing: border-box;
    min-width: var(--spacing-xl);
    border-width: var(--button-border-width);
    border-style: solid;
    //white-space: pre;
    background-color: var(--color-primary-50);
    border-color: transparent;
    color: #ffffff;

    padding: var(--spacing-xs) var(--spacing-l);
    // border-radius: var(--border-radius-button); // <Original JDS>
    border-radius: var(--radius-small);
    vertical-align: top;

    display: inline-flex;
    align-items: center;
    justify-content: space-evenly;

    min-height: var(--button-height-small);

    &:hover {
        background-color: var(--color-primary-60);
    }

    &--type-1 {
        padding: var(--spacing-xs) var(--spacing-massive);

        &:hover {
            background-color: var(--color-primary-60);
        }

        &:active {
            color: var(--color-primary-30);
        }
    }

    &--type-2 {
        padding: var(--spacing-xs) var(--spacing-xxl);

        &:hover {
            background-color: var(--color-primary-60);
        }

        &:active {
            color: var(--color-primary-30);
        }
    }

    &--type-icon-only {
        padding: var(--spacing-xs);

        &:hover {
            background-color: var(--color-primary-60);
        }
    }
}
