// Minimalistic Grid System using flex box
// Breakpoints are based on min-width media queries, meaning they affect that breakpoint and all those above it (e.g., .col-sm-4 applies to sm, md, lg, xl, and xxl).
// Breakpoint xs is the only exception and will affect only upto 576px

// variables
$grid-columns: 12 !default;
// $grid-gutter-width: 1.5em !default;
$grid-gutter-width: var(--grid-gutter-width);
$grid-gutter-height: var(--grid-gutter-height);

@function calculateWidthPercentage($col-num){
    @return calc(($col-num / $grid-columns) * 100%);
}

@function calculateRowColPercentage($row-col-num){
    @return calc(100% / $row-col-num);
}

@mixin col-screens($screen-type) {
    @if $screen-type == ''{
        @for $index from 1 through $grid-columns{
            .col-#{$index}{
                flex: 0 0 auto;
                width: calculateWidthPercentage($index) !important;
            }
        }
    } @else{
        @for $index from 1 through $grid-columns{
            .col-#{$screen-type}-#{$index}{
                flex: 0 0 auto;
                width: calculateWidthPercentage($index) !important;
            }
        }
    }
}

@mixin row-cols($screen-type){
    @if $screen-type == ''{
        @for $index from 1 through $grid-columns{
            .row-cols-#{$index} > *{
                flex: 0 0 auto;
                width: calculateRowColPercentage($index) !important;
            }
        }
    } @else{
        @for $index from 1 through $grid-columns{
            .row-cols-#{$screen-type}-#{$index} > *{
                flex: 0 0 auto;
                width: calculateRowColPercentage($index) !important;
            }
        }
    }
}

.row{
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(#{$grid-gutter-width} * -0.5);
    margin-right: calc(#{$grid-gutter-width} * -0.5);
    margin-top: calc(#{$grid-gutter-height} * -1 );
}

.row > *{
    box-sizing: border-box;
    width: 100% !important;
    max-width: 100%;
    flex-shrink: 0;   
    padding-left: calc(#{$grid-gutter-width} * 0.5);
    padding-right: calc(#{$grid-gutter-width} * 0.5);
    padding-top: $grid-gutter-height;
}

.row-cols-auto > *{
    flex: 0 0 auto !important;
    width: auto !important;
}

@include row-cols('');

@media (max-width: 576px){
    @include row-cols('xs');
}

@media (min-width: 576px){
    @include row-cols('sm');
}

@media (min-width: 576px) and (max-width: 992px){
    @include row-cols('tablet');
}

@media (min-width: 768px){
    @include row-cols('md');
}

@media (min-width: 992px){
    @include row-cols('lg');
}

@media (min-width: 1368px){
    @include row-cols('xl');
}

@media (min-width: 1920px){
    @include row-cols('xxl');
}

.col-auto{
    flex: 0 0 auto;
    width: auto !important;
}

.col{
    flex: 1 0 0%;
}

@include col-screens('');


@media (min-width: 576px){
    @include col-screens('sm');
}

@media (min-width: 768px){
    @include col-screens('md');
}

@media (min-width: 992px){
    @include col-screens('lg');
}

@media (min-width: 1368px){
    @include col-screens('xl');
}

@media (min-width: 1920px){
    @include col-screens('xxl');
}

// four types of screens: sm (small), md (medium), lg (large), xl(extra large), xxl (extra extra large)