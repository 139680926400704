%root-vars {
    /* Smaller spacing unit. Useful between text elements or padding in small atoms. */
    --spacing-xxs: calc(var(--spacing-base) * 0.25);
    /* Role / Description */
    --spacing-xs: calc(var(--spacing-base) * 0.5);
    /* Role / Description */
    --spacing-s: calc(var(--spacing-base) * 0.75);
    /* Role / Description */
    --spacing-base: 1rem;
    /* Role / Description */
    --spacing-m: calc(var(--spacing-base) * 1.5);
    /* Role / Description */
    --spacing-l: calc(var(--spacing-base) * 2);
    /* Role / Description */
    --spacing-xl: calc(var(--spacing-base) * 2.5);
    /* Role / Description */
    --spacing-xxl: calc(var(--spacing-base) * 3);
    /* Role / Description */
    --spacing-huge: calc(var(--spacing-base) * 4);
    /* Role / Description */
    --spacing-massive: calc(var(--spacing-base) * 5);
}