@import 'reset';
@import 'breakpoints';
@import 'text';
@import 'colors';
@import 'layout';
@import 'transitions';
@import 'elements';

%root-vars {
    //--------------BACKDROP----------------
    --backdrop-filter: blur(5px) contrast(0.8);
    //--------------ASPECT RATIO----------------
    --aspect-ratio-wide: 16/9;
    --aspect-ratio-landscape: 4/3;
    --aspect-ratio-square: 1;
    --aspect-ratio-portrait: 4/5;
    --shadow-s: 0px 4px 16px rgba(0, 0, 0, 0.08);
    --shadow-m: 0px 4px 16px rgba(0, 0, 0, 0.16);
    --shadow-l: 0px 4px 16px rgba(0, 0, 0, 0.24);
    --elevation-10: 10;
    --elevation-20: 20;
    --elevation-30: 30;
    --elevation-40: 40;
    --elevation-50: 50;
    --elevation-60: 60;
    --elevation-70: 70;
    --surfaces-soft--bg: rgba(89, 89, 89, 0.5);
    --surfaces-soft--filter: blur(24px);
    --surfaces-heavy--bg: rgba(89, 89, 89, 0.5);
    --surfaces-heavy--filter: blur(64px);
    --surfaces-overlay--bg: rgba(20, 20, 20, 0.4);
    --surfaces-overlay--filter: none;
    --surfaces-blurred-bg--bg: rgba(20, 20, 20, 0.8);
    --surfaces-blurred-bg--filter: blur(24px);
}

body,
html {
    @extend %root-vars;
}

#tvguide {
    .j-button:hover {
        background-color: var(--button-background);
    }
}
