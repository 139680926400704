.text-field {
    height: 28px;
    width: 250px;
    border-radius: 14px;
    border: 2px solid rgb(73, 73, 240);
    outline: none;

    font-family: "JioType", helvetica, arial, sans-serif;
    font-weight: 500;
    text-transform: none;
    font-size: 1rem;
    letter-spacing: -0.08px;
    line-height: 1.5;
}

// Jds text field

.j-text-field {
    border: none;
    border-bottom: 2px solid var(--color-primary-grey-80);
    outline: none;
    padding-bottom: calc(var(--spacing-xs) + 1px);
    margin-bottom: 1px;
    width: 100%;
    transition-duration: var(--transitions-duration-rapid);
    transition-timing-function: var(--transitions-ease-quick);

    font-family: "JioType", helvetica, arial, sans-serif;
    font-weight: 500;
    text-transform: none;
    font-size: 1rem;
    letter-spacing: -0.08px;
    line-height: 1.5;

    &:hover{
        border-bottom: 2px solid var(--color-primary-50);
    }

    &:focus{
        border-bottom: 2px solid var(--color-primary-60);
    }

    
}