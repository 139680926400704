%root-vars {
    /* — */
    --radius-small: 4px;
    /* — */
    --radius-medium: 8px;
    /* — */
    --radius-large: 16px;
    /* — */
    --radius-xl: 24px;
    /* — */
    --radius-xxl: 32px;
    /* — */
    --radius-pill: 250px;
}
