* {
    box-sizing: border-box;
}

body {
    margin: 0;
    //font-family: var(--font-primary), 'Helvetica', 'arial', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}

img {
    width: 100%;
    object-fit: contain;
}
