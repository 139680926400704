%root-vars {
    /* Interactive curve. Used primary in subtle  passive animations, when the user is viewing but not interacting with the interface. */
    --transitions-ease-rapid: cubic-bezier(0.35, 0, 0.5, 1);
    /* Interactive curve. Used primarily for interactions where it instantly moves based off the user’s interaction . */
    --transitions-ease-quick: cubic-bezier(0.35, 0, 0.25, 1);
    /* Interactive curve. Similar to quick curve, it adds an additional overshoot (bounce) giving more life to the motion. It is primarily in instances of progression. */
    --transitions-ease-joyful: cubic-bezier(0.35, 1.3, 0.3, 1);
    /* Used for elements and events which brings a new item into the screen */
    --transitions-ease-entrance: cubic-bezier(0, 0, 0.1, 1);
    /* Used for elements and events which an item exits the screen */
    --transitions-ease-exit: cubic-bezier(0.35, 0, 0.8, 1);
    /* Used for elements and events primarily which call attention when they enter the screen  */
    --transitions-ease-joyfulentrance: cubic-bezier(0.15, 1.3, 0.3, 1);
    /* Used for elements and events primarily which call attention when they exit the screen  */
    --transitions-ease-joyfulexit: cubic-bezier(0.7, -0.1, 0.6, 0.1);
}
  